<template>
  <div>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card elevation="0">
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-container fluid>
      <v-card elevation="0">
        <v-toolbar elevation="0">
          <v-row class="d-flex justify-space-around">
            <v-col cols="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
                height="40"
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="2">
              <v-btn color="primary" @click="newItem">Novo</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="tributes"
          :search="search"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon small class="mr-2" @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-row justify="center">
      <v-dialog v-model="openDialog" persistent max-width="800px">
        <v-card elevation="0">
          <v-form>
            <v-toolbar>
              <span class="headline" style="font-size: 16px !important"
                >Tributos</span
              >
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Nome"
                      v-model="name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="type"
                      :items="types"
                      item-text="name"
                      item-value="name"
                      label="Tipo de Tributo"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-autocomplete
                      :items="collectors"
                      v-model="collector"
                      label="Orgão Arrecadante"
                      item-text="name"
                      item-value="name"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    v-if="
                      collector && collector !== 'Receita Federal do Brasil'
                    "
                    cols="12"
                    sm="6"
                  >
                    <v-autocomplete
                      :items="states"
                      v-model="uf"
                      label="UF"
                      item-text="nome"
                      item-value="sigla"
                      @change="getCities"
                    ></v-autocomplete>
                  </v-col>
                  <v-col
                    v-if="collector === 'Receita Municipal' && uf"
                    cols="12"
                    sm="6"
                  >
                    <v-autocomplete
                      :items="cities"
                      v-model="county"
                      label="Municipio Arrecadante"
                      item-text="nome"
                      item-value="nome"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red" text @click="clear"> Limpar </v-btn>
              <v-btn color="blue darken-1" text @click="closeDialog">
                Fechar
              </v-btn>
              <v-btn
                :loading="loadingAction"
                color="success"
                @click="registerOrUpdate()"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import axios from "axios";
import http from "@/services/http";
export default Vue.extend({
  name: "Tributes",
  data: () => ({
    deleteDialog: false,
    tributes: [],
    cities: [],
    states: [],
    state: "",
    types: [
      {
        name: "Tributo Federal",
      },
      {
        name: "Tributo Estadual",
      },
      {
        name: "Tributo Municipal",
      },
    ],
    collectors: [
      {
        name: "Receita Federal do Brasil",
      },
      {
        name: "Receita Estadual",
      },
      {
        name: "Receita Municipal",
      },
    ],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    tributeId: "",
    name: "",
    type: "",
    uf: "",
    county: "",
    collector: "",

    openDialog: false,
    headers: [
      {
        text: "Nome",
        align: "start",
        value: "name",
        filtering: true,
      },
      {
        text: "Tipo",
        value: "type",
        filtering: true,
      },
      {
        text: "Orgção Arrecadante",
        value: "collector",
        filtering: true,
      },
      {
        text: "UF",
        value: "uf",
        default: "--",
        filtering: true,
      },
      {
        text: "Município",
        value: "county",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    deleteOpen(itemId) {
      this.tributeId = itemId;
      this.deleteDialog = true;
    },
    getItems() {
      this.loading = true;
      http.get("tributes").then((data) => {
        this.tributes = data.data;
        this.loading = false;
      });
    },
    newItem() {
      this.openDialog = true;
    },
    clear() {
      this.tributeId = "";
      this.name = "";
      this.type = null;
      this.uf = null;
      this.county = null;
      this.collector = null;
    },
    deleteItem() {
      http.delete(`tributes/${this.tributeId}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.deleteDialog = false;
          this.getItems();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
    getStates() {
      axios
        .get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`)
        .then((data) => {
          this.states = data.data;
        });
    },
    getCities(uf) {
      let selectedUf = "";
      if (uf) {
        selectedUf = uf;
      } else {
        selectedUf = this.uf;
      }
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${selectedUf}/municipios
`
        )
        .then((data) => {
          this.cities = data.data;
        });
    },
    registerOrUpdate() {
      this.loadingAction = true;
      const payload = {
        name: this.name,
        type: this.type,
        collector: this.collector,
        uf: this.uf,
        county: this.county,
      };
      if (this.tributeId) {
        http.put(`tributes/${this.tributeId}`, payload).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("tributes", payload).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    closeDialog() {
      this.openDialog = false;
      this.clear();
      this.getItems();
    },
    editItem(item) {
      this.getCities(item.uf);
      this.tributeId = item.id;
      this.name = item.name;
      this.collector = item.collector;
      this.type = item.type;
      this.uf = item.uf;
      this.county = item.county;
      this.openDialog = true;
    },
  },
  mounted() {
    this.getItems();
    this.getStates();
  },
});
</script>